<template>
  <div :class="{ 'is-active': active === title }" class="sf-mega-menu-column">
    <slot
      name="title"
      v-bind="{ title, changeActive: hvMegaMenu.changeActive }"
    >
      <HvMenuItem
        :label="title"
        class="sf-mega-menu-column__header"
        @click="hvMegaMenu.changeActive(title)"
      />
    </slot>
    <div class="sf-mega-menu-column__content">
      <slot />
    </div>
  </div>
</template>

<script>
import HvMenuItem from '../HvMenuItem.vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HvMegaMenuColumn',
  components: {
    HvMenuItem,
  },
  inject: ['hvMegaMenu'],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    active() {
      return this.hvMegaMenu.active;
    },
  },
});
</script>
