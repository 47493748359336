<template>
  <div>
    <div v-if="isSearchModalOpen" class="search-modal" style="top: 0">
      <IconCloseBlack
        class="filters-sidebar__top-icon search-modal-close-icon"
        @click="
          toggleSearchModal();
          removeSearchResults();
        "
      />
      <SearchBarCustom
        ref="searchBarRef"
        class="sf-header__search sf-search-bar"
        :value="term"
        @keydown="handleSearch($event)"
        @focus="isSearchOpen = false"
        @keydown.esc="
          toggleSearchModal();
          removeSearchResults();
        "
        @keydown.enter="handleEnterSearch($event)"
        :on-arrow-click="(value) => handleEnterSearch(value)"
      />
      <SearchResults
        :visible="isSearchOpen"
        :result="result"
        :value="term"
        @close="toggleSearchModal"
        @removeSearchResults="removeSearchResults()"
        @searchPagination="searchPagination()"
        :current-page="searchPageNumber"
      />
    </div>
    <SfOverlay
      :visible="isSearchModalOpen"
      @click="toggleSearchModal"
      class="search-overlay"
    />
  </div>
</template>

<script>
import { SfOverlay } from '@storefront-ui/vue';
import { useUiHelpers, useUiState, useWindow } from '~/composables';
import {
  useFacet,
  useCategorySearch,
  categoryGetters,
} from '@gemini-vsf/composables';
import {
  defineComponent,
  ref,
  useContext,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import SearchBarCustom from '~/components/Search/SearchBarCustom.vue';
import SearchResults from '~/components/Search/SearchResults.vue';
import debounce from 'lodash.debounce';
import { IconCloseBlack } from '~/components/General/Icons';
import { SearchCategoriesToExclude } from '~/assets/SearchCategoriesToExclude.js';
import { useGtm } from '~/composables';

export default defineComponent({
  name: 'SearchModal',
  components: {
    IconCloseBlack,
    SearchBarCustom,
    SearchResults,
    SfOverlay,
  },

  setup() {
    const { isSearchModalOpen, toggleSearchModal } = useUiState();

    const { handleHtmlClass, handleBodyClass } = useWindow();

    const { searchGtmPush } = useGtm();

    watch(isSearchModalOpen, (newValue) => {
      if (newValue) {
        handleHtmlClass('addClass', 'no-scroll');
      } else {
        handleHtmlClass('removeClass', 'no-scroll');
      }
    });

    const { getFacetsFromURL } = useUiHelpers();

    const { result: searchResult, search: productsSearch } =
      useFacet('AppHeader:Products');
    const { result: categories, search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories'
    );

    const term = ref(getFacetsFromURL().term);
    const isSearchOpen = ref(false);
    const result = ref(null);

    const closeSearch = () => {
      if (!result) {
        if (!isSearchOpen.value) return;

        term.value = '';
        isSearchOpen.value = false;
      }
    };

    const searchPageNumber = ref(1);

    const removeSearchResults = () => {
      result.value = null;
      term.value = '';
      isSearchOpen.value = false;
      searchPageNumber.value = 1;
    };

    const searchPagination = async () => {
      searchPageNumber.value += 1;

      handleBodyClass('addClass', 'loading_search');
      await productsSearch({
        itemsPerPage: 3,
        page: searchPageNumber.value,
        term: term.value,
        customQuery: {
          products: 'productListCustom',
        },
      });
      result.value = {
        ...result.value,
        products: [
          ...result.value.products,
          ...(searchResult.value?.data?.items ?? {}),
        ],
      };
      handleBodyClass('removeClass', 'loading_search');
    };

    const handleSearch = debounce(async (event) => {
      term.value = event.target ? event.target.value : event;

      handleSearch.cancel();
      if (term?.value && term.value.length >= 4 && !event.ctrlKey) {
        handleBodyClass('addClass', 'loading_search');
        searchGtmPush(term.value);
        await productsSearch({
          itemsPerPage: 3,
          page: 1,
          term: term.value,
          customQuery: {
            products: 'productListCustom',
          },
        });

        const aggregations = searchResult.value?.data?.availableFilters;
        const catsIds = aggregations?.find((aggr) => {
          return aggr?.attribute_code === 'cats_ids';
        });

        const populatedCatsIds = catsIds?.options
          .filter((catId) => {
            return !SearchCategoriesToExclude.has(
              catId.value.split('::').pop()
            );
          })
          .map((catDataPopulated) => {
            return catDataPopulated?.value.split('::').pop();
          });

        const catIdsCount = {};

        catsIds?.options.forEach((catDataPopulated) => {
          catIdsCount[catDataPopulated?.value.split('::').pop()] =
            catDataPopulated.count;
        });

        await categoriesSearch({
          filters: { category_uid: { in: populatedCatsIds } },
        });

        categories.value.sort((a, b) => {
          if (
            catIdsCount?.[a.uid.split('::').pop()] >
            catIdsCount?.[b.uid.split('::').pop()]
          ) {
            return -1;
          }
          if (
            catIdsCount?.[a.uid.split('::').pop()] <
            catIdsCount?.[b.uid.split('::').pop()]
          ) {
            return 1;
          }
          return 0;
        });

        isSearchOpen.value = true;
        result.value = {
          products: searchResult.value?.data?.items,
          categories: categories.value.map((element) =>
            categoryGetters.getCategoryTree(element)
          ),
          total: searchResult.value?.data?.total,
        };
        handleHtmlClass('removeClass', 'no-scroll');
        handleBodyClass('removeClass', 'loading_search');
      }
    }, 1000);

    const router = useRouter();
    const {
      app: { localeRoute },
    } = useContext();

    const handleEnterSearch = (event) => {
      term.value = event.target ? event.target.value : event;
      if (term?.value && term.value !== '') {
        handleSearch.cancel();
        router.push(localeRoute(`/search?term=${term.value}`));
        toggleSearchModal();
      }
    };

    return {
      isSearchModalOpen,
      toggleSearchModal,
      handleSearch,
      handleEnterSearch,
      closeSearch,
      removeSearchResults,
      result,
      isSearchOpen,
      term,
      searchPagination,
      searchPageNumber,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-modal {
  position: fixed;
  top: 0;
  background: var(--c-white);
  width: 100%;
  max-width: 100%;
  height: 100vh;
  z-index: 10;

  @include to-tablet-max {
    height: 10rem;
    width: 100vw;
    z-index: 9999999;
  }

  .search-modal-close-icon {
    position: absolute;
    right: var(--spacer-base);
    top: var(--spacer-base);
    @include pointer;
  }

  .search-bar-icon {
    .sf-icon {
      svg {
        width: 1.25rem;
      }
    }
  }
}

@include from-desktop-min {
  .search-modal {
    .sf-search-bar {
      margin: 3.75rem auto;
    }
  }
}
.search-overlay {
  z-index: 2;
}
</style>
