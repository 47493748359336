<template>
  <HvMegaMenu
    :visible="isSearchOpen"
    :title="$t('Search results')"
    class="search"
    :class="{ search__page: isSearchPage }"
  >
    <transition name="sf-fade" mode="out-in">
      <div
        v-if="products && products.length > 0"
        key="results"
        class="search__wrapper-results"
      >
        <div class="results-listing">
          <div class="categories-search-results">
            <ul
              v-if="categories && categories.length > 0"
              class="categories-search-results__secondary-categories"
              :class="{
                'category-list-scrolling': categories.length > 5,
                'category-list-scrolling__search-page': isSearchPage,
              }"
            >
              <li class="list-categories-title">
                <span>
                  {{ $t('Categories') }}
                </span>
              </li>
              <li
                v-for="(category, index) in categories.slice(0, 30)"
                :key="index"
              >
                <SfLink
                  :link="localePath(category.slug)"
                  @click.native="!isSearchPage && toggleSearchModal()"
                >
                  {{ category.label }}
                </SfLink>
              </li>
            </ul>
          </div>
          <div
            class="products-search-results"
            :class="{ 'products-search-results__  search-page': isSearchPage }"
          >
            <ProductsGrid
              v-if="!loading && products.length > 0"
              :products="products"
              id="search-products-grid"
              @click.native="closeSearchResults()"
              :key="`search-grid-${Date.now()}`"
              grid-id="search-results"
            />
            <div v-if="!isPaginationDisable" class="load-more-button">
              <CustomCta
                id="more-products"
                @click="
                  () => {
                    $emit('searchPagination');
                  }
                "
                :disabled="loading"
              >
                {{ $t('Load More') }}
              </CustomCta>
            </div>
            <div v-else class="no-more-result">
              <span>{{ $t('All products loaded') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!isLoading" key="no-results" class="no-results-wrapper">
        <p class="before-results__paragraph">
          {{ $t('Sorry, there are no results that match your search') }}
        </p>
        <div class="search__wrapper-results"></div>
      </div>
    </transition>
  </HvMegaMenu>
</template>

<script>
import { SfLink } from '@storefront-ui/vue';
import { ref, watch, computed, defineComponent } from '@nuxtjs/composition-api';
import { productGetters, useUser } from '@gemini-vsf/composables';
import { useUiHelpers, useUrlHelpers, useUiState } from '~/composables';
import HvMegaMenu from '~/components/Search/HvMegaMenu.vue';
import { addBasePath } from '~/helpers/addBasePath';
import { CustomCta } from '~/components/General';
import { ProductsGrid } from '~/components/Catalog';

export default defineComponent({
  name: 'SearchResults',
  components: {
    HvMegaMenu,
    SfLink,
    CustomCta,
    ProductsGrid,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [Number, String],
      default: null,
    },
    paginationSize: {
      type: Number,
      default: 3,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isAuthenticated } = useUser();
    const { buildProductUrl } = useUrlHelpers();
    const { toggleSearchModal } = useUiState();

    const th = useUiHelpers();
    const isSearchOpen = ref(props.visible);
    const products = computed(() => {
      const result = props.result;
      return result?.products;
    });

    const categories = computed(() => {
      return props.result?.categories;
    });

    const totalResults = computed(() => {
      const result = props.result;
      return result?.total;
    });

    const isPaginationDisable = computed(
      () => props.currentPage * props.paginationSize >= totalResults.value
    );

    watch(
      () => props.visible,
      (newValue) => {
        isSearchOpen.value = newValue;
        if (isSearchOpen.value) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
          emit('removeSearchResults');
        }
      }
    );

    const closeSearchResults = () => {
      !props.isSearchPage && toggleSearchModal();
      emit('removeSearchResults');
    };

    return {
      th,
      isSearchOpen,
      productGetters,
      products,
      categories,
      isAuthenticated,
      closeSearchResults,
      buildProductUrl,
      addBasePath,
      totalResults,
      isPaginationDisable,
      toggleSearchModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.brand-list-scrolling,
.category-list-scrolling {
  @include from-desktop-min {
    height: 175px;
    width: 200px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: black;
    }
  }
}

.category-list-scrolling__search-page {
  height: unset;
  max-height: 100%;
}

.btn-close-search-result {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  @include for-mobile {
    top: 24px;
    right: 16px;
  }
}

.search__page {
  height: unset;
  padding-bottom: 0;
}

.search {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 3;
  --mega-menu-column-header-margin: var(--spacer-sm) 0 var(--spacer-xl);
  --mega-menu-height: auto;

  .results-listing {
    @include from-landscape-min {
      grid-template-columns: 0.7fr 3fr;
    }
    margin: auto;
    .products-search-results {
      padding-bottom: 8rem;
      .products-search-results__title {
        margin-bottom: var(--space-xs);

        @include to-tablet-max {
          padding-left: 1.5625rem;
          margin-top: var(--space-s);
        }
      }
      .load-more-button {
        margin-top: 3.75rem;
        margin-bottom: 6.25rem;

        #more-products {
          margin: auto;
          max-width: 250px;
        }
      }
      .no-more-result {
        margin-top: 3.75rem;
        margin-bottom: 6.25rem;
        text-align: center;
        span {
          text-align: center;
          text-transform: uppercase;
        }
      }

      &__search-page {
        padding-bottom: 0;
      }
    }
    .categories-search-results {
      ul {
        list-style-type: none;
        padding-left: unset;

        @include from-desktop-min {
          margin-top: 0;
        }

        @include to-tablet-max {
          display: flex;
          gap: var(--space-xs);
          border-bottom: 1px solid var(--c-light-grey);
          padding: 0.9375rem 1.5625rem;
          margin: 0;
        }

        li {
          margin-bottom: 0.75rem;
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }

          @include to-tablet-max {
            margin-bottom: 0;
            min-width: max-content;
            padding-right: var(--spacer-base);
          }
        }
      }
      &__primary-categories {
        margin-top: 0;
        li {
          a {
            @include paragraph-m;
            text-transform: uppercase;
          }
        }
      }
      &__secondary-categories {
        span {
          @include paragraph-m;
        }
        li {
          a,
          span {
            @include paragraph-m;
          }
        }
        .list-categories-title {
          span {
            @include desktop-h6;

            @include to-tablet-max {
              @include desktop-h7;
            }
          }
        }

        @include to-tablet-max {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .search-menu-list-item {
          width: max-content;
          &:hover {
            border-bottom: 1px solid black;
          }
        }
        .search-menu-link {
          &:hover {
            text-decoration: none;
          }
        }
      }
      span {
        padding-bottom: 1.0625rem;
      }
    }
  }
}
.results {
  &--mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: var(--c-white);
    padding: var(--spacer-base) var(--spacer-sm);
    --product-card-max-width: 9rem;
  }
}

.before-results {
  box-sizing: border-box;
  padding: var(--spacer-base) var(--spacer-sm) var(--spacer-2xl);
  width: 100%;
  text-align: center;
  @include for-desktop {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  &__picture {
    --image-width: 230px;
    margin-top: var(--spacer-2xl);
    @include for-desktop {
      --image-width: 18.75rem;
      margin-top: var(--spacer-base);
    }
  }
  &__paragraph {
    margin: 0 auto;
    margin-bottom: 1.25rem;
    max-width: max-content;

    @include from-desktop-min {
      margin-bottom: 3.75rem;
    }
    @include to-tablet-max {
      text-align: center;
      padding-top: var(--spacer-xl);
      @include mobile-h4-lowercase;
    }
  }
  &__button {
    margin: var(--spacer-xl) auto;
    width: 100%;
  }
}

.search__wrapper-results {
  margin-top: var(--spacer-lg);
  width: 100%;
  @include to-tablet-max {
    border-top: 1px solid var(--c-black);
  }
}

@include from-desktop-min {
  .results-listing {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .categories-search-results {
      padding-left: var(--spacer-base);
      &__primary-categories {
        margin-top: 0;
        margin-bottom: 3.125rem;
      }
    }
    .products-search-results {
      padding-right: 6.125rem;
    }
  }
}
</style>

<style lang="scss">
.search {
  .sf-mega-menu__menu {
    display: block;
  }
}
.sf-mega-menu {
  display: block;

  @include for-mobile {
    height: 100vh !important;
    overflow-y: scroll;
    padding-bottom: 15.625rem;
  }

  &.search {
    @include for-desktop {
      height: 100vh;
      padding-bottom: 9.375rem;
      overflow-y: scroll;

      .sf-scrollable__content {
        max-height: unset;
      }
    }
    .sf-mega-menu__content {
      max-width: 100%;
      padding: 0;
    }
    .sf-mega-menu__bar {
      display: none;
    }

    #search-products-grid {
      .product-card {
        border: none;
      }
    }
  }

  .search__categories {
    @include for-desktop {
      display: none;
    }
    @include for-mobile {
      flex: auto;
      .sf-mega-menu-column__content {
        display: none;
      }
    }
  }
}
</style>
