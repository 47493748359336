<template>
  <div
    class="sf-search-bar"
    :class="{ 'sf-search-bar__full-width': isSearchPage }"
  >
    <input
      class="sf-search-bar__input"
      type="search"
      :value="value"
      v-bind="$attrs"
      :placeholder="$t(placeholder)"
      v-on="listeners"
    />
    <slot name="icon">
      <ArrowNext @click="onArrowClick(value)" />
    </slot>
  </div>
</template>

<script>
import { SfIcon } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { ArrowNext } from '~/components/General/Icons';

export default defineComponent({
  name: 'SearchBarCustom',
  components: { SfIcon, ArrowNext },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: 'Write something to search',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    onArrowClick: {
      type: Function,
      default: () => () => {},
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        'keyup.esc': () => this.$emit('input', ''),
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.sf-search-bar {
  width: 100%;
  max-width: calc(100% - var(--spacer-lg));
  border: none;
  border-bottom: 1px solid var(--c-black);

  &__full-width {
    max-width: 100%;
  }

  &__input {
    @include paragraph-m;
    border: var(--black-border);
    border-radius: 0;
    height: 3.125rem;

    &:focus-visible,
    &:focus {
      outline: none;
    }

    @include to-tablet-max {
      font-size: 1rem;
    }
  }
}

@include to-tablet-max {
  .sf-search-bar {
    display: flex;
    width: auto;
    padding-bottom: 1.5625rem;
    margin: 0;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>

<style lang="scss">
@import '@storefront-ui/shared/styles/components/molecules/SfSearchBar.scss';

.sf-search-bar {
  .search-bar-icon {
    .sf-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
